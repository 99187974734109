/* src/assets/fonts/fonts.css */

@font-face {
    font-family: 'Recoleta-Regular';
    src: local('Recoleta-Regular'),
        url('./Recoleta-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ArsenicaTrial-Regular';
    src: local('ArsenicaTrial-Regular'),
        url('./ArsenicaTrial-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ArsenicaTrial-Medium';
    src: local('ArsenicaTrial-Medium'),
        url('./ArsenicaTrial-Medium.ttf') format('truetype');
    font-weight: medium;
    font-style: medium;
}

@font-face {
    font-family: 'Epilogue-Regular';
    src: local('Epilogue-Regular'),
        url('./Epilogue-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Epilogue-Medium';
    src: local('Epilogue-Medium'),
        url('./Epilogue-Medium.ttf') format('truetype');
    font-weight: medium;
    font-style: medium;
}

@font-face {
    font-family: 'Epilogue-Bold';
    src: local('Epilogue-Bold'),
        url('./Epilogue-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: 'Epilogue-Black';
    src: local('Epilogue-Black'),
        url('./Epilogue-Black.ttf') format('truetype');
    font-weight: black;
    font-style: black;
}

@font-face {
    font-family: 'Epilogue-ExtraBold';
    src: local('Epilogue-ExtraBold'),
        url('./Epilogue-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: 800;
}

@font-face {
    font-family: 'Epilogue-SemiBold';
    src: local('Epilogue-SemiBold'),
        url('./Epilogue-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'EBGaramond-Regular';
    src: local('EBGaramond-Regular'),
        url('./EBGaramond-Regular.ttf') format('truetype');
    font-weight: 200;
    font-style: 200;
}

@font-face {
    font-family: 'EBGaramond-Medium';
    src: local('EBGaramond-Medium'),
        url('./EBGaramond-Medium.ttf') format('truetype');
    font-weight: 400;
    font-style: 400;
}

@font-face {
    font-family: 'EBGaramond-Bold';
    src: local('EBGaramond-Bold'),
        url('./EBGaramond-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: 600;
}

@font-face {
    font-family: 'EBGaramond-Italic';
    src: local('EBGaramond-Italic'),
        url('./EBGaramond-Italic.ttf') format('truetype');
    font-weight: 200;
    font-style: 200;
}